.web-action-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .app-translateAssist {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;


    .identify-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;


      .identify-section-flex {
        flex: 1;

        h4 {
          margin: 0 0 8px;
          font-size: 16px;
          color: #535252;
        }
      }

      .swap-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 50px;


        .swap-button {
          align-self: center;

          cursor: pointer;
          font-size: 16px;
          background: transparent;
          border: none;
        }
      }


    }

    .translate-section {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;

      gap: 50px;

      .translate-section-flex {
        flex: 1;
        position: relative;
      }

      .identify-textarea {
        width: 100%;
        height: 100%;
        white-space: normal;
        border: 1px solid #ccc;
        overflow-wrap: break-word;
        padding-right: 30px;
        position: relative;
        resize: none;
        box-sizing: border-box;
        font-size: 16px;
      }

      .clear-button {
        position: absolute;
        top: 10px;
        right: 5%;
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 16px;
        color: #888;
      }




      .translate-text-section-flex {
        flex: 1;
        position: relative;
      }

      .translate-textarea {
        width: 100%;
        height: 100%;
        // background-color: #f0f0f0;
        border: 1px solid #ccc;
        white-space: normal;
        overflow-wrap: break-word;
        resize: none;
        box-sizing: border-box;
        font-size: 16px;
      }


    }

    .action {
      margin-top: 30px;
      margin-bottom: 20px;
    }

  }
}