.set-background{
    display: grid;
    grid-template-columns: repeat(4,auto);
    gap: 20px;
    button{
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 50%;
        overflow: hidden;
    }
    .color-point{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 3px;
        padding: 3px;
        &-white{
            background: #DEDBD8;
        }
    }

}
.custom-color{
    display: flex;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}