.translate-AI-popup {
  padding: 10px;
  padding-bottom: 1px;
  margin: 0 auto;
  width: 200%;
  background-color: #ffffff;
  display: grid;
  height: 360px;
  width: 220px;


  .identify-section {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 20px;

    .identify-section-flex {
      flex: 1;

      h4 {
        margin: 0 0 4px;
        font-size: 16px;
        color: #535252;
      }

      .language-list {
        display: flex;
        flex-direction: column;
        max-height: calc(8 * 30px);
        overflow-y: auto;

        .language-item {
          padding: 2px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 16px;
        }

        .language-item:hover {
          background-color: #e0e0e0;
        }

        .language-item.selected {
          background-color: #d0d0d0;
        }


      }

      .note-language-list {
        display: flex;
        flex-direction: column;
        max-height: calc(6 * 30px);
        overflow-y: auto;

        .language-item {
          padding: 2px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 16px;
        }

        .language-item:hover {
          background-color: #e0e0e0;
        }

        .language-item.selected {
          background-color: #d0d0d0;
        }


      }

    }

  }

  .translate-section {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 10px;

    .translate-section-flex {
      flex: 1;

      h4 {
        margin: 0 2px 4px 0px;
        font-size: 16px;
        color: #535252;
      }
    }
  }

}